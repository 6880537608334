import React, { ReactNode, useReducer } from "react"
import RosterManagementReducer, { initialState } from "./reducer/roster-management.reducer";

import { ContextType } from "./type/roster-management.type";

export const RosterManagementContext = React.createContext({} as ContextType);
export const RosterManagementProvider: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const [rosterManagementState, dispatch] = useReducer(RosterManagementReducer, initialState);

    return (
        <RosterManagementContext.Provider value={{ rosterManagementState, dispatch }}>
            {children}
        </RosterManagementContext.Provider>
    )
}
export const useRosterManagement = () => React.useContext(RosterManagementContext);
