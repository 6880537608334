import React from 'react';
import IPage from '../../_interfaces/page';
import HeroSection from './components/HeroSection';
import TutorialSection from './components/TutorialSection';
import EmailSubscriptionSection from './components/EmailSubscriptionSection';

const HomePage: React.FunctionComponent<IPage> = () => {

    return (
        <div>
            <HeroSection />
            <TutorialSection />
            <EmailSubscriptionSection />
            <div className='h-150px sm:h-200px'></div>
        </div >
    );
};

export default HomePage;
