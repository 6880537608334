import { toast } from 'react-toastify';

export const toastTopLeftError = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    hideProgressBar: false,
    position: toast.POSITION.TOP_LEFT,
    pauseOnHover: true
}

export const toastTopRightError = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    hideProgressBar: false,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true
}

export const toastCenterSuccess = {
    autoClose: 6000,
    type: toast.TYPE.SUCCESS,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_CENTER,
    pauseOnHover: true
}
export const toastCenterError = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_CENTER,
    pauseOnHover: true
}