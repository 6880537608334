import React, { FC, useEffect } from 'react';
import * as serviceWorkerRegistration from '../../app/serviceWorkerRegistration';


const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        console.log('new sw update 2')
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload();
    };

    return (
        showReload ? < div className="fixed z-1000 bg-black text-white rounded-lg p-2 " >
            A new version is available!
            <div onClick={() => reloadPage()}>Reload</div>
        </div > : null
    );
}

export default ServiceWorkerWrapper;