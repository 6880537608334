import { collection, doc, setDoc } from "firebase/firestore";
import { ErrorHandling } from "../../../common/utils/error-handling";
import { firestore } from "../../../common/utils/firebase";

export const homeService = {
    subscribeNewsletter
}

async function subscribeNewsletter(email: string) {
    try {
        const docSnap = doc(collection(firestore, 'newsletter'));
        await setDoc(docSnap, { email });
        return docSnap.id;
    } catch (error) {
        ErrorHandling.logError('ubscribeNewsletter', error, 'newsletter');
    }
}
