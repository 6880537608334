import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from "react-hook-form";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    options?: any
}

const FormInputComponent: React.FunctionComponent<Props> = ({ options, className, name, ...props }) => {
    const { register } = useFormContext();
    const style = classNames(`py-2 px-3 outline-none
    bg-white border-2 border-gray-300 focus:ring-1 focus:ring-primary-shade focus:border-primary-main rounded-md
    ${props.disabled ? 'bg-gray-200' : null}`, className);
    
    return (
        <input
            {...props}
            {...register(name!, { ...options })}
            className={style}
        />
    )
};

export default FormInputComponent;