import { analytics, db } from './firebase';
import { logEvent } from "firebase/analytics";
import { ref, push } from "firebase/database";
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';

export const ErrorHandling = {
    logError
}

function logError(label, error, location, trace = null) {
    var message, name, stack = 'none';
    var errToString = error.toString();
    if (error instanceof Error) {
        message = error?.message ? error?.message : 'none';
        name = error?.name ? error?.name : 'none';
        stack = error?.stack ? error?.stack : 'none';
    }
    push(ref(db, `errors/${location}/${label}`), {
        label,
        browserName,
        browserVersion,
        osName,
        osVersion,
        error: errToString,
        name: name || '',
        message: message || '',
        stack: stack || '',
        timeStamp: Date.now(),
    });

    logEvent(analytics, label, { error_type: error });
    if (trace !== null) {
        trace.putAttribute("End_Result", `${label}_error`);
        trace.putAttribute("Error", error.message);
    }
}