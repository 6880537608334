import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from '@firebase/database';
import { getStorage } from 'firebase/storage';
const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_DATABASE_URL,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID,
    REACT_APP_MEASUREMENT_ID,
    REACT_APP_DEV_FIREBASE_API_KEY,
    REACT_APP_DEV_AUTH_DOMAIN,
    REACT_APP_DEV_DATABASE_URL,
    REACT_APP_DEV_PROJECT_ID,
    REACT_APP_DEV_STORAGE_BUCKET,
    REACT_APP_DEV_MESSAGING_SENDER_ID,
    REACT_APP_DEV_APP_ID,
    REACT_APP_DEV_MEASUREMENT_ID,
    REACT_APP_PUBLIC_PROD_URL,
    REACT_APP_PUBLIC_DEV_URL,
} = process.env;

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE_URL,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
    appId: REACT_APP_APP_ID,
    measurementId: REACT_APP_MEASUREMENT_ID,
};

const firebaseDevConfig = {
    apiKey: REACT_APP_DEV_FIREBASE_API_KEY,
    authDomain: REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: REACT_APP_DEV_DATABASE_URL,
    projectId: REACT_APP_DEV_PROJECT_ID,
    storageBucket: REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: REACT_APP_DEV_APP_ID,
    measurementId: REACT_APP_DEV_MEASUREMENT_ID,
};

let app;
let functionURL: string | undefined;
if (process.env.REACT_APP_ENV === 'production') {
    app = initializeApp(firebaseConfig)
    functionURL = REACT_APP_PUBLIC_PROD_URL;
} else {
    console.log('Start Dev Environment')
    app = initializeApp(firebaseDevConfig);
    functionURL = REACT_APP_PUBLIC_DEV_URL;
}


export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore();
export const db = getDatabase();
export const storage = getStorage();
export const cloudFunctionURL = functionURL;
export default app;