import React, { useState } from 'react';
import config from '../../../_config/config';
import { homeService } from '../services/home.service';

const EmailSubscriptionSection: React.FC<{}> = () => {
    const [email, setEmail] = useState<string>('');
    const [subscribed, setSubscribed] = useState<boolean>(false);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
    }

    function subscribe() {
        setSubscribed(true);
        homeService.subscribeNewsletter(email);
    }

    return <section className="w-full p-5 mx-auto pt-60">
        <div className="flex flex-col justify-center items-center py-10 text-center space-y-5 bg-primary-dark rounded-xl max-w-750px mx-auto p-5">
            <div className="font-semibold md:text-4xl text-2xl text-white py-5">Subscribe to Our Newsletter</div>
            <div className='flex justify-center items-center py-2 px-2'>
                {subscribed ?
                    <div className='text-white font-bold text-2xl'>Thank you </div>
                    :
                    <div className='flex flex-row justify-center items-center bg-white px-2 rounded-xl'>
                        <input
                            name="email"
                            className="max-w-350px w-full border-0 ring-0 px-5 rounded-full focus:outline-none focus:ring-0"
                            type="email"
                            value={email}
                            onChange={handleChange}
                        />
                        <button onClick={() => subscribe()} className='font-semibold rounded-full bg-primary-main hover:bg-primary-shade text-white px-2 py-1 text-sm'>Subscribe</button>
                    </div>}
            </div>
            <div className="font-semibold text-sm text-white max-w-lg">
                We'll never share your information. By subscribing to our newletter, you agree to our
                <a className="text-white hover:text-gray-300" target="_blank" href={config.privacyPolicy}>{` Privacy Policy `}</a>
                and
                <a className="text-white hover:text-gray-300" target="_blank" href={config.termsOfService}>{` Terms & Conditions`}</a>
                . You can unsubscribe any time.</div>
        </div>
    </section >
}

export default EmailSubscriptionSection;