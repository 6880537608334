import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { menu } from './config/config';
import UserAvatar from '../UserAvatar';
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

const ProfileMenu: React.FunctionComponent<{ user: any }> = ({ user }) => {
    return (
        <Menu as="div" className="ml-3 relative z-10">
            <div>
                <Menu.Button className="flex items-center text-sm rounded-full focus:outline-none focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    <UserAvatar
                        avatar={user?.avatar}
                        firstName={user.firstName}
                        lastName={user.lastName}
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {menu.map((item) => (
                        <Menu.Item key={item.name}>
                            {({ active }) => (
                                <a href={item.href} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                    {item.name}
                                </a>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default ProfileMenu;
