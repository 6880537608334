import { ActionType, RosterManagementStateInterface } from "../type/roster-management.type";


export const initialState = { roster: {}, entries: [], tableColumns: [], loading: false, dialog: null, numOfNewUpdates: 0, rosterId: null };
const RosterManagementReducer = (state: RosterManagementStateInterface, action: ActionType): any => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                ...action.payload,
            };
        case 'SET_ROSTER':
            return {
                ...state,
                roster: action.payload,
            };
        case 'SET_ENTRIES':
            return {
                ...state,
                entries: action.payload,
            };
        case 'SET_TABLE_COLUMNS':
            return {
                ...state,
                tableColumns: action.payload,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'SET_DIALOG':
            return {
                ...state,
                dialog: action.payload,
            };
        case 'PURGE_STATE':
            return initialState;
        default:
            return state;
    }
};

export default RosterManagementReducer;