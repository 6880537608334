import { AnnotationIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import ButtonComponent from '../../common/components/ButtonComponent';
import FormInputComponent from '../../common/components/FormInputComponent';
import FormTextareaComponent from '../../common/components/FormTextareaComponent';
import Modal from '../../common/components/Modal';
import { feedbackService } from '../../_services/feedback.service';

const word = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.08,
        }
    }
}

const letter = {
    hidden: {
        opacity: 0,
        y: 50
    },
    visible: {
        opacity: 1,
        y: 0
    }
}

const FeedbackComponent: React.FC<{}> = () => {
    const methods = useForm();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    useEffect(() => {
        const location = window.location.href
        methods.setValue('location', location);
    }, [window.location.href]);

    const onSubmit = async (data: FieldValues) => {
        await feedbackService.sendFeedback(data);
        methods.reset();
        close();
    };

    return (
        <div >
            <AnimatePresence
                initial={false}
                exitBeforeEnter={true}
                onExitComplete={() => null}
            >
                {isOpen ? <Modal handleClose={() => close()}>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className='flex flex-col space-y-2 p-6 w-full h-screen sm:h-full'>
                                <div>
                                    <label className='text-sm'>Email</label>
                                    <FormInputComponent
                                        name='email'
                                        className='w-full'
                                    />
                                </div>
                                <div>
                                    <label className='text-sm'>Comment</label>
                                    <FormTextareaComponent
                                        name='comment'
                                    />
                                </div>
                                <div className='flex-1'></div>
                                <ButtonComponent size='sm font-semibold' type='submit'>Submit Feedback</ButtonComponent>
                            </div>
                        </form>
                    </FormProvider>
                </Modal> : null}
            </AnimatePresence>


            <AnimatePresence >
                {!isOpen ? <motion.button className='cursor-pointer bg-primary-purple text-white rounded-full px-3 py-1 fixed bottom-10 right-10 float-right' onClick={() => open()}>
                    <motion.div className='flex items-center space-x-1'>
                        <AnnotationIcon className='w-5 h-5'></AnnotationIcon>
                        <motion.div
                            className='hidden sm:block'
                            variants={word}
                            initial='hidden'
                            animate='visible'
                            exit='hidden'
                        >{
                                'Feedback'.split("").map((char, index) => {
                                    return <motion.span
                                        key={`${char}-${index}`}
                                        variants={letter}
                                    >{char}</motion.span>
                                })
                            }</motion.div>
                    </motion.div>
                </motion.button> : null}
            </AnimatePresence>
        </div>

    )
}

export default FeedbackComponent