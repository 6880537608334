
import React from 'react';
import defaultImage from '../../common/assets/logos/purple.jpg';

const UserAvatar: React.FunctionComponent<{
    avatar?: any,
    firstName:string,
    lastName:string
}> = ({
    avatar,
    firstName,
    lastName
}) => {
        return (
            <>
                <img
                    className="h-8 w-8 rounded-full"
                    alt="profile menu"
                    src={avatar ? avatar : defaultImage}
                />
                <span className="text-gray-800 font-semibold px-1 py-1">
                    {firstName} {lastName}
                </span>
            </>
        )
    }

export default UserAvatar;