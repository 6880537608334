
import { ActionType, AuthStateInterface } from '../type/auth.type';

const initialState = { loggedIn: false, user: null, persistence: 'local' };
const AuthReducer = (state: AuthStateInterface, action: ActionType): any => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload,
            };
        case 'AUTHENTICATE_USER':
            return {
                ...state,
                loggedIn: action.payload,
            };
        case 'SET_PERSISTENCE':
            return {
                ...state,
                persistence: action.payload,
            };
        case 'PURGE_STATE':
            return initialState;
        default:
            return state;
    }
};

export default AuthReducer;