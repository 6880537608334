import React from 'react';
import {
    AdjustmentsIcon,
    DatabaseIcon,
    LightningBoltIcon,
    SwitchHorizontalIcon,
} from '@heroicons/react/outline';
import rosterSetUpGif from '../../../common/assets/gifs/roster_set_up.gif';
import memberSignUpGif from '../../../common/assets/gifs/member_sign_up.gif';
import memberInteractionGif from '../../../common/assets/gifs/member_interaction.gif';
import dataManagementGif from '../../../common/assets/gifs/data_management.gif';
import { motion } from 'framer-motion';

const TutorialSection: React.FC<{}> = () => {
    return <section className="bg-primary-dark w-full p-3 md:p-8 mx-auto">
        <div className="py-10 md:px-10 flex flex-col justify-center max-w-1600px mx-auto">
            <div className="flex flex-col mx-auto w-full space-y-40">
                <div className="flex flex-col md:flex-row text-gray-700">
                    <div className="md:w-1/2 flex flex-col mx-auto justify-center items-center rounded-lg px-3 py-5 sm:p-5 space-y-5 w-full">
                        <div className='-ml-5 flex justify-center items-center space-x-2'>
                            <LightningBoltIcon className="text-yellow-300" width={50} height={50}></LightningBoltIcon>
                            <div className="font-bold md:text-4xl text-2xl text-white">Rapid Set Up</div>
                        </div>
                        <div className="text-lg md:text-2xl text-center max-w-600px text-white">Create a roster with a few clicks.</div>
                        {/* <img src={bg2} className="max-w-200px" alt='bg 2' ></img> */}
                    </div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className='p-3 md:max-w-1/2 w-full mx-auto rounded-lg bg-green-600 shadow-lg'>
                        <img src={rosterSetUpGif} alt='background 2' />
                    </motion.div>
                </div>

                <div className="flex flex-col md:flex-row-reverse text-gray-700 ">
                    <div className="md:w-1/2 flex flex-col mx-auto justify-center items-center rounded-lg px-3 py-5 sm:p-5 space-y-5 w-full">
                        <div className='-ml-5 flex justify-center items-center space-x-2'>
                            <AdjustmentsIcon className="text-green-400" width={50} height={50}></AdjustmentsIcon>
                            <div className="font-bold md:text-4xl text-2xl text-white">Get Everyone On Board</div>
                        </div>
                        <div className="text-lg md:text-2xl text-center max-w-600px text-white">With one link, your members can sign up, update information, and ask questions. All in one place.</div>
                        {/* <img src={bg3} className="max-w-200px" alt='bg 3' ></img> */}
                    </div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className='p-3 md:max-w-1/2 w-full mx-auto rounded-lg bg-green-600 shadow-lg'>
                        <img src={memberSignUpGif} alt='background 3'></img>
                    </motion.div>
                </div>

                <div className="flex flex-col md:flex-row text-gray-700 ">
                    <div className="md:w-1/2 flex flex-col mx-auto justify-center items-center rounded-lg px-3 py-5 sm:p-5 space-y-5 w-full">
                        <div className='-ml-5 flex justify-center items-center space-x-2'>
                            <SwitchHorizontalIcon className="text-red-400" width={50} height={50}></SwitchHorizontalIcon>
                            <div className="font-bold md:text-4xl text-2xl text-white">Member Interaction</div>
                        </div>
                        <div className="text-lg md:text-2xl text-center max-w-600px text-white">Align with members through chat and tags.</div>
                        {/* <img src={bg4} className="max-w-200px" alt='bg 4' ></img> */}
                    </div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className='p-3 md:max-w-1/2 w-full mx-auto rounded-lg bg-green-600 shadow-lg'>
                        <img src={memberInteractionGif} alt='background 4'></img>
                    </motion.div>

                </div>

                <div className="flex flex-col md:flex-row-reverse  text-gray-700 ">
                    <div className="md:w-1/2 flex flex-col mx-auto justify-center items-center rounded-lg px-3 py-5 sm:p-5 space-y-5 w-full">
                        <div className='-ml-5 flex justify-center items-center space-x-2'>
                            <DatabaseIcon className="text-yellow-300" width={50} height={50}></DatabaseIcon>
                            <div className="font-bold md:text-4xl text-2xl text-white">Data Management</div>
                        </div>
                        <div className="text-lg md:text-2xl text-center max-w-600px text-white">Search, sort, update and export membership data from a comprehensive dashboard.</div>
                        {/* <img src={bg5} className="max-w-200px" alt='bg 3' ></img> */}
                    </div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className='p-3 md:max-w-1/2 w-full mx-auto rounded-lg bg-green-600 shadow-lg'>
                        <img src={dataManagementGif} alt='background 5'></img>
                    </motion.div>
                </div>

            </div>
        </div>
    </section>
}

export default TutorialSection;