import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../../common/components/ButtonComponent';
import { useAuth } from '../../../common/provider/auth/AuthProvider';
import config from '../../../_config/config';
import bg1 from '../../../common/assets/svgs/bg1.svg';
import { motion } from 'framer-motion'
import GraphemeSplitter from "grapheme-splitter";

const HeroSection: React.FC<{}> = () => {
    const conversationTypes = [`Club 🙌`, `Team ⚽`, `Event 🎉`, `Activity 🏂`, `Class 🎻`, `Group 🎮`, `Trip 🛫`];
    const { userState: { user } } = useAuth();

    const stringSplitter = (string: string) => {
        const splitter = new GraphemeSplitter();
        return splitter.splitGraphemes(string);
    };

    return <section className="w-full py-10 md:p-14 lg:p-18">
        <div className='container mx-auto py-5 md:p-10 flex flex-col md:flex-row justify-around items-center max-w-1600px h-full'>
            <div className='font-semibold flex flex-col justify-center md:justify-start items-center md:items-start px-8'>
                <div className="md:my-5 flex flex-col justify-center md:space-y-4">
                    <div className="md:text-5xl text-3xl text-gray-800 text-center md:text-left">Organize Your</div>
                    <div className='mx-auto md:mx-0'>
                        <Typewriter
                            options={{
                                strings: conversationTypes,
                                autoStart: true,
                                loop: true,
                                wrapperClassName: 'text-primary-main md:text-5xl text-3xl',
                                cursorClassName: 'md:text-left text-primary-main md:text-5xl text-3xl',
                                /* @ts-ignore */
                                stringSplitter
                            }}
                        />
                    </div>

                    <div className="sm:text-md md:text-xl text-gray-800 leading-tight text-center md:text-left font-normal py-10">
                        <div>
                            Don't let email and spreadsheets slow you down. Use JoinSlip to collect, search, sort, and update member data. Assign roles and set statuses with tags. Answer member questions with build-in chat. Everything in one lightweight tool.
                        </div>
                    </div>
                </div>
                <div className="sm:pb-2">
                    <Link to={user ? config.path.dashboard : config.path.login}>
                        <ButtonComponent
                            weight='normal'
                            size='lg'
                        >Get Started Now</ButtonComponent>
                    </Link>
                </div>
            </div>

            <motion.img
                whileHover={{ scale: 1.02 }}
                src={bg1}
                alt='background 1'
                className='hidden md:flex p-10 max-w-500px max-h-500px bg-gray-100 rounded-xl shadow-lg '
            ></motion.img>
        </div>
    </section >
}

export default HeroSection;