import config from "../../../../_config/config";

export const menu = [{
    href: config.path.dashboard,
    name: 'Dashboard'
},{
    href: config.path.userManagement,
    name: 'Account'
},{
    href: config.path.login,
    name: 'Sign out'
}]