import React from 'react';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../common/components/ButtonComponent';
import NotFoundImage from '../../common/assets/svgs/404-page-not-found.svg';
import config from '../../_config/config';

const NotFound: React.FC<{}> = () => (
    <div className="flex flex-col justify-center items-center h-screen space-y-5">
        <img src={NotFoundImage} className='max-w-400px pb-10'/>
        <Link to={config.path.home}>
            <ButtonComponent >Go Home</ButtonComponent>
        </Link>
    </div>
);

export default NotFound;
