import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const HomeLayout: React.FunctionComponent<{ children: any }> = ({ children }) => {

    return (
        <>
            <div className="flex flex-col min-h-screen max-h-screen">
                <Header></Header>
                <div className={`flex-1 overflow-y-auto h-full relative`}>
                    <div className="flex flex-col justify-center items-center min-h-screen">
                        <div className="min-h-screen w-full relative">{children}</div>
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeLayout;
