import React, {  useEffect } from 'react';
import { useHistory } from 'react-router';
import { auth } from '../utils/firebase';
import Header from '../components/header';
import Footer from '../components/footer';
import config from '../../_config/config';

const MainLayout: React.FunctionComponent<{ children: any }> = ({ children }) => {
    const history = useHistory();

    useEffect(() => {
        auth.currentUser?.reload().then(() => {
            if (!auth.currentUser?.emailVerified) history.push(config.path.verifyAccount);
        });
    }, [history]);

    return (
        <>
            <div className="flex flex-col min-h-screen max-h-screen">
                <Header></Header>
                <div className={`flex-1 overflow-y-auto h-full relative`}>
                    <div className="flex flex-col justify-center items-center min-h-screen">
                        <div className="min-h-screen w-full max-w-7xl p-5 sm:p-10 relative">{children}</div>
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainLayout;
