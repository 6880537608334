import React from 'react';
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer: React.FC<{}> = () => {

    let version = process.env.REACT_APP_VERSION;
    return (
        <div className="flex w-full text-center items-center p-5">
            <div className="w-full text-center">
                <div className="flex flex-row justify-center space-x-2 pb-2">
                    <Link to={{ pathname: "https://www.facebook.com/TheJoinSlip/" }} target="_blank">
                        <FaFacebookSquare className='w-7 h-7 text-gray-400 hover:text-gray-500 cursor-pointer'></FaFacebookSquare>
                    </Link>

                    <Link to={{ pathname: "https://twitter.com/joinslip?t=ujUiKhUDwKXxo8utRtPSew&s=09" }} target="_blank">
                        <FaTwitterSquare className='w-7 h-7 text-gray-400 hover:text-gray-500 cursor-pointer'></FaTwitterSquare>
                    </Link>
                </div>
                <div className="text-xs text-gray-500">v{version}</div>
                <div className="text-sm text-gray-500">© 2022 JoinSlip All Rights Reserved</div>
            </div>
        </div>
    );
};

export default Footer;
