import React from 'react';
import IRoute from '../_interfaces/route';
import NotFound from '../features/notfound/NotFound';
import AuthLayout from '../common/layout/AuthLayout';
import MainLayout from '../common/layout/MainLayout';
import HomeLayout from '../common/layout/HomeLayout';
import HomePage from '../features/home/home';
import config from './config';

const AboutPage = React.lazy(() => import('../features/about/About'));
const DashboardPage = React.lazy(() => {
    return Promise.all([
        import('../features/dashboard/Dashboard'),
        new Promise(resolve => setTimeout(resolve, 1000))
    ]).then(([moduleExports]) => moduleExports);
});
const CreateRosterPage = React.lazy(() => import('../features/roster/CreateRoster'));
const LoginPage = React.lazy(() => import('../features/auth/Login'));
const SignUpPage = React.lazy(() => import('../features/auth/SignUp'));
const VerifyEmailForm = React.lazy(() => import('../features/auth/Verify'));
const SendResetPasswordPage = React.lazy(() => import('../features/auth/ResetPassword'));
const RosterGatewayPage = React.lazy(() => import('../features/roster/RosterGateway'));
const UserManagementPage = React.lazy(() => import('../features/user-management/UserManagement'));
const RosterManagementPage = React.lazy(() => import('../features/roster/RosterManagement'));

const routes: IRoute[] = [
    {
        path: config.path.home,
        name: 'Home Page',
        component: HomePage,
        layout: HomeLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.about,
        name: 'About Page',
        component: AboutPage,
        layout: MainLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.userManagement,
        name: 'User Management Page',
        component: UserManagementPage,
        layout: MainLayout,
        auth: true,
        exact: true
    },
    {
        path: config.path.dashboard,
        name: 'Dashboard Page',
        component: DashboardPage,
        layout: MainLayout,
        auth: true,
        exact: true
    },
    {
        path: config.path.createRoster,
        name: 'Create Roster Page',
        component: CreateRosterPage,
        layout: MainLayout,
        auth: true,
        exact: true
    },
    {
        path: config.path.login,
        name: 'Login Page',
        component: LoginPage,
        layout: AuthLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.signup,
        name: 'Sign Up Page',
        component: SignUpPage,
        layout: AuthLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.resetPassword,
        name: 'Reset Password Page',
        component: SendResetPasswordPage,
        layout: AuthLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.verifyAccount,
        name: 'Verify Email Page',
        component: VerifyEmailForm,
        layout: AuthLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.entry,
        name: 'Roster Gateway',
        component: RosterGatewayPage,
        layout: MainLayout,
        auth: false,
        exact: true
    },
    {
        path: config.path.rosterManagement,
        name: 'Roster Management',
        component: RosterManagementPage,
        layout: MainLayout,
        auth: true,
        exact: true
    },
    {
        path: config.path.notFound,
        name: '404 Not Found Page',
        component: NotFound,
        auth: false,
        exact: false
    },
]

export default routes;