import React, { ReactNode, useReducer } from "react"
import AuthReducer from "./reducer/auth.reducer";
import { ContextType } from "./type/auth.type";

export const AuthContext = React.createContext({} as ContextType);
export const useAuth = () => React.useContext(AuthContext);
export const AuthProvider: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [userState, dispatch] = useReducer(AuthReducer, initializeState());

  function initializeState() {
    /*
     the order in which the the data is compared is very important;
     first try to populate the state from Storage if not return initialState
    */

    if (typeof (Storage) !== 'undefined') {
    } else {
      throw new Error('You need to enable Storage to run this app.');
    }

    let userData: any = JSON.parse(localStorage.getItem('user') || '{}');
    const initialState = userData ?
      { loggedIn: true, user: userData, persistence: 'local' }
      :
      { loggedIn: false, user: null, persistence: 'local' };

    return initialState;
  }
  
  return (
    <AuthContext.Provider value={{ userState, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}



