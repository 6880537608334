const config = {
    default: {
        namespace: 'Application'
    },
    privacyPolicy: "https://thoughtful-thyme-e8f.notion.site/JoinSlip-Privacy-Policy-046ad788e4f947f08fc051adc44b0754",
    termsOfService: "https://thoughtful-thyme-e8f.notion.site/JoinSlip-Terms-Conditions-c63a89d55bda46c693affc58d578e52a",
    path: {
        home: '/',
        about: '/about',
        userManagement: '/account',
        dashboard: '/dashboard',
        createRoster: '/roster/create',
        login: '/login',
        signup: '/signup',
        resetPassword: '/resetpassword',
        verifyAccount: '/verify',
        entry: '/roster/entry/:id',
        rosterManagement: '/roster/management/:id',
        notFound: '*'
    }
}

export default config;