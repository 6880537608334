import { XIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import { createPortal } from "react-dom";


const dropIn = {
    hidden: {
        y: "100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.3,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};


const Modal: React.FC<{
    title?: string,
    heading?: boolean,
    handleClose: () => void
}> = ({
    children,
    title = '',
    heading = true,
    handleClose
}) => {
        // const ref = useRef(null);
        // useOnClickOutside(ref, handleClose);

        return createPortal(
            <div className="fixed top-0 left-0 h-full w-full ">
                <motion.div
                    onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                    className={`relative z-10 sm:rounded sm:m-auto w-full
                    flex flex-col sm:items-center sm:justify-center 
                    h-full max-h-screen overflow-hidden`}
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div
                        className="bg-white sm:rounded-lg min-h-screen max-h-screen sm:min-h-250px sm:max-h-750px sm:min-w-500px flex flex-col"
                    >
                        {heading ? <div className='flex justify-between items-center pt-3 px-3'>
                            <div className='font-semibold text-lg pl-3 pr-1'>{title}</div>
                            <XIcon
                                className='w-7 h-7 cursor-pointer hover:bg-gray-500 hover:text-white rounded-full p-1'
                                onClick={handleClose} />
                        </div> : null}
                        <div className="h-full overflow-y-scroll sm:scrollbar-thin sm:scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 sm:scrollbar-thumb-rounded-full">
                            {children}
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    className={`absolute top-0 left-0 h-screen w-screen bg-backdrop overflow-y-hidden m-auto`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={handleClose}
                >
                </motion.div>
            </div>
            , document.getElementById('root')!
        );
    };


export default Modal;