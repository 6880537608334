/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import { Link } from 'react-router-dom';
import logo from '../../assets/logos/JoinSlipLogo.png';
import icon from '../../assets/logos/JoinSlipLogoIcon.png';
import { useEffect } from 'react';
import { useAuth } from '../../provider/auth/AuthProvider';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import ButtonComponent from '../ButtonComponent';
import config from '../../../_config/config';

const Header: React.FC<{
    bgColor?: string
    border?: boolean,
    container?: boolean
}> = ({
    bgColor = 'white',
    border = true,
    container = false
}) => {
        const { userState: { user } } = useAuth();
        const userExists = user && Object.keys(user).length > 0 ? true : false;

        useEffect(() => {
            onAuthStateChanged(auth, (user) => {
                if (!user) {
                    localStorage.removeItem('user');
                }
            });
        }, []);

        function setRouteBack() {
            localStorage.setItem('joinslip_route_back', JSON.stringify(window.location.pathname));
        }

        return (
            <Disclosure as="nav" className={`bg-${bgColor}`}>
                {({ open }) => (
                    <>
                        <div className={`mx-auto px-2 sm:px-6 lg:px-8 ${border ? 'shadow' : ''}  ${container ? 'container mx-auto' : ''}`}>
                            <div className="relative flex items-center justify-between h-16">
                                <div className="absolute inset-y-0 right-2 flex items-center sm:hidden">
                                    {/* Mobile menu button*/}
                                    {userExists && <MobileMenu user={user}></MobileMenu>}
                                </div>
                                <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link to={config.path.home}>
                                            <img className="block lg:hidden h-8 w-auto" src={icon} alt="Workflow" />
                                        </Link>
                                        <Link to={config.path.home}>
                                            <img className="hidden lg:block h-8 w-auto" src={logo} alt="Workflow" />
                                        </Link>
                                    </div>
                                </div>
                                {userExists ? (
                                    <div className="invisible sm:visible absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                        {/* <NotificationButton></NotificationButton> */}
                                        {/* Profile dropdown */}
                                        <ProfileMenu user={user}></ProfileMenu>
                                    </div>
                                ) : (
                                    <div className='flex items-center space-x-4'>
                                        <Link 
                                        to={config.path.login} 
                                        className='font-bold text-gray-800 border-2 border-gray-800 px-3 rounded-xl hover:bg-gray-800 hover:text-white' 
                                        onClick={setRouteBack}
                                        >
                                            Login
                                        </Link>
                                        <ButtonComponent
                                            weight='medium'
                                        >
                                            <Link to={config.path.signup} onClick={setRouteBack}>
                                                Sign Up
                                            </Link>
                                        </ButtonComponent>
                                    </div>

                                )}
                            </div>
                        </div>
                    </>
                )}
            </Disclosure>
        );
    }


export default Header