import React from "react";

const NoLayout: React.FunctionComponent<{children: any}> = ({ children }) => {
    return (
        <>
                        {children}

        </>
    )
}

export default NoLayout;