import React, { Suspense, useEffect, useState } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import IRoute from '../../_interfaces/route';
import { useAuth } from '../provider/auth/AuthProvider';
import NoLayout from '../layout/NoLayout';
import LoadingScreenComponent from '../components/LoadingScreenComponent';
import config from '../../_config/config';

const AppRoute: React.FunctionComponent<IRoute> = (route) => {
    const Layout = route.layout ? route.layout : NoLayout;
    const [mounted, setMounted] = useState<boolean>(false);
    const history = useHistory();
    const { userState:{ user } } = useAuth();

    useEffect(() => {
        if (route.auth && Object.keys(user).length === 0) {
            history.push(config.path.login);
        } else {
            setMounted(true);
        }
    }, []);

    return mounted ? (
        <Suspense fallback={<LoadingScreenComponent/>}>
            <Route
                path={route.path}
                exact={route.exact}
                render={(props: RouteComponentProps<any>) => (
                    <Layout>
                        <route.component name={route.name} {...props} {...route.props} />
                    </Layout>
                )}
            />
        </Suspense>

    ) : null;
};

export default AppRoute;
