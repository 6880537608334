import React, { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routes from '../_config/routes';
import AppRoute from '../common/utils/appRoute';
import FeedbackComponent from '../features/feedback/Feedback';
import ServiceWorkerWrapper from '../common/components/ServiceWorkerWrapper';

const Application: React.FunctionComponent<{}> = (props) => {

    const history = useHistory();

    useEffect(() => {
        setInterval(() => {
            console.log('checking for sw update...')
            navigator.serviceWorker
            .getRegistrations()
            .then((regs) => regs.forEach((reg) => reg.update()));
        }, 90000);
    }, []);

    return (
        <div>
            <ServiceWorkerWrapper />
            <ToastContainer
                hideProgressBar
                transition={Slide}
            />
            <Switch>
                {routes.map((route, index) => {
                    return <AppRoute key={index} {...route} />;
                })}
            </Switch>
            <FeedbackComponent></FeedbackComponent>
        </div>
    );
};

export default Application;
