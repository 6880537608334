import React from 'react';
import loading from '../assets/images/loading.gif';

const LoadingScreenComponent: React.FC<{ visible?: boolean }> = ({ visible = true }) => {
    return (
        <div className={`${visible ? 'flex-1' : 'hidden'} z-10 bg-white flex-grow overflow-y-auto`}>
            <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                <img src={loading} width={70} height={70} alt="Loading" />
                <div className="animate-pulse text-gray-500">Loading...</div>
            </div>
        </div>
    );
};

export default LoadingScreenComponent;
