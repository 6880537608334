import React from 'react';
import ReactDOM from 'react-dom';
import './common/assets/styles/index.css';

import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './common/provider/auth/AuthProvider';
import Application from './app/application';
import reportWebVitals from './app/reportWebVitals';
import { RosterManagementProvider } from './features/roster/RosterManagement/provider/RosterManagementProvider';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <RosterManagementProvider>
        <BrowserRouter>
          <Application />
        </BrowserRouter>
      </RosterManagementProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
