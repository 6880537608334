import { collection, doc, setDoc } from "firebase/firestore";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { toastCenterSuccess } from "../common/constants/toast";
import { ErrorHandling } from "../common/utils/error-handling";
import { firestore } from "../common/utils/firebase";

export const feedbackService = {
    sendFeedback
}

async function sendFeedback(data: FieldValues) {
    try {
        const docSnap = doc(collection(firestore, 'feedback'));
        await setDoc(docSnap, data);
        toast('Thank you for submitting feedback', toastCenterSuccess);
        return docSnap.id;
    } catch (err) {
        ErrorHandling.logError('sendFeedback', err, 'feedback');
    }
}