import React from 'react';
import { Popover } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import defaultImage from '../../../common/assets/logos/purple.jpg';
import { menu } from './config/config';


const MobileMenu: React.FunctionComponent<{ user: any }> = ({ user }) => {

    return (
        <div>
            {/* <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
            </Disclosure.Button> */}
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <Popover.Button >
                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                        <Popover.Overlay
                            className={`${open ? 'opacity-30 fixed inset-0' : 'opacity-0'
                                } bg-black`}
                        />
                        <Popover.Panel className="z-10 fixed inset-2">
                            <div className='w-full bg-white border rounded-lg min-h-sm px-5 py-2 relative'>
                                <Popover.Button>
                                    <XIcon className="absolute h-6 w-6 top-2 right-2" aria-hidden="true" />
                                </Popover.Button>
                                <div className='flex flex-row'>
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        className="h-8 w-8 rounded-full"
                                        alt="profile menu"
                                        src={user?.avatar ? user.avatar : defaultImage}
                                    />
                                    <span className="text-gray-800 font-semibold px-1 py-1">
                                        {user.firstName} {user.lastName}
                                    </span>
                                </div>
                                <div className='py-3'>
                                    {menu.map((item) => (
                                        <div key={item.name} className='hover:bg-gray-200 rounded-lg'>
                                            <a href={item.href} className={'font-semibold text-lg p-1 block text-sm text-gray-700'}>
                                                {item.name}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                )}
            </Popover>
        </div>

    )
}

export default MobileMenu;