import classNames from 'classnames';
import React from 'react';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    tooltip?: string;
    size?: string;
    hollow?: boolean;
    toggle?: boolean;
    rounded?: string;
    weight?: string;
    loading?: boolean;
    loadingMsg?: string;
}


const ButtonComponent: React.FunctionComponent<IButton> = ({
    children,
    tooltip = '',
    loading = false,
    loadingMsg = 'Loading...',
    hollow = false,
    toggle = false,
    rounded = 'md',
    weight = 'normal',
    size = 'md',
    ...props }) => {

    return (
        <>
            <button
                {...props}
                className={classNames(`
                border-2 py-1 px-3
                whitespace-nowrap 
                transition delay-100 duration-200
                text-${size} ${props.className}
                font-${weight}
                rounded-${rounded}
                `
                    , {
                        'py-1 px-3': size === 'sm',
                        'py-2 px-4': size === 'md',
                        'py-2 px-5': size === 'lg',
                        'border-gray-400 text-gray-400': hollow && props.disabled,
                        'bg-gray-400 cursor-default text-gray-100': !hollow && props.disabled
                    }, toggle ? {
                        'border-red-600 text-red-600 hover:bg-red-600 hover:text-gray-100': hollow && !props.disabled,
                        'bg-red-500 border-red-500 hover:bg-red-600 text-gray-100': !hollow && !props.disabled,

                    } : {
                    'border-primary-shade text-primary-shade hover:bg-primary-shade hover:text-gray-100': hollow && !props.disabled,
                    'bg-primary-shade border-primary-shade hover:bg-primary-main hover:border-primary-main text-gray-100': !hollow && !props.disabled,
                })}
            >
                {loading ? <div className='flex space-x-2 items-center'>
                    <svg className="animate-spin h-5 w-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <div>{loadingMsg}</div>
                </div> : children}
            </button >
        </>
    );
};

export default ButtonComponent;
