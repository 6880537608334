import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from "react-hook-form";


const FormTextareaComponent: React.FunctionComponent<InputHTMLAttributes<HTMLTextAreaElement>> = ({ className, name, ...props }) => {
    const { register } = useFormContext();
    const style = classNames(className, `w-full resize-none focus:ring-primary-shade focus:border-primary-shade block pl-2 pr-5 sm:text-sm border-2 border-gray-300  rounded-md`)

    return (
        <textarea
            rows={8}
            {...props}
            {...register(name!)}
            className={style}
        />
    )
};

export default FormTextareaComponent