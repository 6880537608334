import React from "react";
import image from '../../common/assets/images/background.jpg';

const AuthLayout: React.FunctionComponent<{ children: any }> = ({ children }) => {

    return (
        <>
            <div className={`relative md:flex`}>
                <div className="md:w-1/2">
                    <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
                        {children}
                    </div>
                </div>
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img
                        className="object-cover object-center w-full h-full"
                        src={image}
                        alt="Authentication"
                    />
                </div>
            </div>
        </>
    )
}

export default AuthLayout;